import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Container from './container';

const StyledHeader = styled.header`
  width: 100%;
  background: linear-gradient(
        -213deg,
        rgb(94, 49, 220) 0%,
        rgb(49, 85, 220) 100%
      )
      right center / contain no-repeat,
    left center rgb(49, 85, 220);
  background-position: right center, left center;
  background-repeat: no-repeat;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
`;

const Logo = styled(Link)`
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  padding: 6px;
  margin-right: 10px;
  border-radius: 3px;
  &.active {
    background: rgba(40, 28, 77, 0.3);
  }
  :hover {
    background: rgba(40, 28, 77, 0.3);
  }
  :last-child {
    margin-right: 0;
  }
`;

export default function Header() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <StyledHeader>
      <Container>
        <Nav>
          <div>
            <Logo to="/">{data.site.siteMetadata.title}</Logo>
          </div>
          <div>
            <NavLink activeClassName="active" partiallyActive={true} to="/blog">
              Blog
            </NavLink>
            <NavLink activeClassName="active" to="/about">
              About
            </NavLink>
          </div>
        </Nav>
      </Container>
    </StyledHeader>
  );
}
