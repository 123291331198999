import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import reset from '../lib/reset';
import Hero from '../components/hero';
import Header from '../components/header';
import Footer from '../components/footer';
import Container from '../components/container';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;

const Body = styled.main`
  flex: 1 0 auto;
`;

const GlobalStyles = createGlobalStyle`
  ${reset};
`;

export default function layout({ children, hero }) {
  return (
    <Wrapper>
      <GlobalStyles />
      <Header />
      {hero && <Hero />}
      <Body>
        <Container>{children}</Container>
      </Body>
      <Footer />
    </Wrapper>
  );
}
