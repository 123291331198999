import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

import image from '../images/akib.jpeg';

export default function Head({
    title,
    description,
    bannerUrl = image,
    isBlogPost = false,
}) {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                }
            }
        }
    `);

    const { description: mainDescription, title: mainTitle } =
        data.site.siteMetadata;
    const siteTitle = title ? `${title} | ${mainTitle}` : mainTitle;
    const siteDesc = description ? description : mainDescription;

    return (
        <Helmet>
            {/* General tags */}
            <title>{siteTitle}</title>
            <meta name="description" content={siteDesc} />
            <meta name="image" content={bannerUrl} />

            {/* OpenGraph tags */}
            {isBlogPost ? <meta property="og:type" content="article" /> : null}
            <meta property="og:title" content={siteTitle} />
            <meta property="og:description" content={siteDesc} />
            <meta property="og:image" content={bannerUrl} />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={siteTitle} />
            <meta name="twitter:description" content={siteDesc} />
            <meta name="twitter:image" content={bannerUrl} />
        </Helmet>
    );
}
