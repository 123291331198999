import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faLinkedin,
  faGithub,
} from '@fortawesome/free-brands-svg-icons';

import Container from './container';

const StyledFooter = styled.footer`
  width: 100%;
  padding: 40px 0;
  margin-top: 20px;
  background: #231c42;
  color: #fff;
  background-repeat: no-repeat;
  @media (max-width: 560px) {
    padding: 25px 10px;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 560px) {
    flex-direction: column;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
`;

const SocialLink = styled.a`
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
  font-size: 26px;
  margin-right: 16px;
  :hover {
    color: rgba(255, 255, 255, 1);
  }
  :last-child {
    margin-right: 0;
  }
`;

const CursiveLogo = styled(Link)`
  display: flex;
  flex-direction: column;
  color: #fff;

  @media (max-width: 560px) {
    display: none;
  }
`;

const LastName = styled.span`
  margin-left: 20px;
`;

export default function Footer() {
  return (
    <StyledFooter>
      <StyledContainer>
        <CursiveLogo to="/">
          <span>Akibur</span>
          <LastName>Rahman</LastName>
        </CursiveLogo>
        <SocialLinks>
          <SocialLink href="https://github.com/akib22" target="__blank">
            <FontAwesomeIcon icon={faGithub} />
          </SocialLink>
          <SocialLink
            href="https://www.linkedin.com/in/akibur/"
            target="__blank"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </SocialLink>
          <SocialLink href="https://twitter.com/akibur227" target="__blank">
            <FontAwesomeIcon icon={faTwitter} />
          </SocialLink>
        </SocialLinks>
      </StyledContainer>
    </StyledFooter>
  );
}
