import React from 'react';
import styled from 'styled-components';

import Container from './container';

const StyledHero = styled.div`
  width: 100%;
  height: 50vh;
  color: #fff;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(
        213deg,
        rgb(49, 85, 220) 0%,
        rgb(94, 49, 220) 100%
      )
      right center / contain no-repeat,
    left center rgb(49, 85, 220);
  background-position: left center, right center;
  background-repeat: no-repeat;
`;

const HeroText = styled.h1`
  margin: 0;
  max-width: 80%;
  line-height: 1.5;
  font-size: 31px;
  height: 100%;
  @media (max-width: 560px) {
    max-width: 100%;
    font-size: 26px;
  }
`;

export default function Hero() {
  return (
    <StyledHero>
      <Container>
        <HeroText>
          Hi! I am Akibur Rahman. I am a full-stack developer. I love to share
          what I have learned and experienced.
        </HeroText>
      </Container>
    </StyledHero>
  );
}
