import styled from 'styled-components';

const Container = styled.div`
  max-width: 750px;
  margin: 0 auto;

  @media (max-width: 767px) {
    padding: 0 15px;
  }
`;

export default Container;
